import ExtendWrite from "@/ext/extend-write";
import AbbrWrite from "@/ext/abbr-write";
import ModifyWrite from "@/ext/modify-write";
import ContinueWrite from "@/ext/continue-write";
import ExportContent from "@/ext/export";
import {Boot} from "@wangeditor/editor";


const m1 = {
    key: 'extendWrite', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new ExtendWrite()
    },
}
const m2 = {
    key: 'abbrWrite', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new AbbrWrite()
    },
}
const m3 = {
    key: 'modifyWrite', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new ModifyWrite()
    },
}
const m4 = {
    key: 'continueWrite', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new ContinueWrite()
    },
}
const m5 = {
    key: 'exportContent', // 定义 menu key ：要保证唯一、不重复（重要）
    factory() {
        return new ExportContent()
    },
}

const module = {                      // JS 语法
    menus: [m1, m2, m3, m4, m5],
}

Boot.registerModule(module)