class ContinueWrite {

    constructor(callback) {
        this.title = '续写'
        this.tag = 'button'
    }


    // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
    isActive(editor) {
        return false
    }

    // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
    getValue(editor) {
        return editor.getSelectionText()
    }

    // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
    isDisabled(editor) {
        return !editor.getSelectionText()
    }

    // 点击菜单时触发的函数
    exec(editor, value) {
        if (this.isDisabled(editor)) return
        editor.insertText(value)

    }


}

export default ContinueWrite